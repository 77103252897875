const settings = {
  version: '04.02.25',
  logs: [
    'Statuts: ✔ Terminé ... En cours ✘ À faire'
  ],
  root: 'https://partenaires.groupeoceanic.fr',
  host: 'https://partenaires.groupeoceanic.fr/api/',
  upload: 'https://partenaires.groupeoceanic.fr/upload/',
  email: 'kartine29@gmail.com',
  logo: 'logo.png',
  program: {
    status: ['Non publié', 'En commercialisation', 'Vendu ou livré'],
    region: ['Métropole', 'Outre-mer'],
    dpe: {
      classe: ['Aucune', 'A', 'B', 'C', 'D', 'E', 'F', 'G']
    },
    certification: {
      rt: ['Aucune', 'RTAA DOM', 'RT 2012', 'RE 2020'],
      nf: ['Aucune', 'NF Habitat', 'NF Habitat HQE']
    }
  },
  lot: {
    status: ['Disponible', 'Optionné', 'Réservé', 'Financé', 'Acté'],
    law: ['Aucun', 'Pinel', 'Pinel OM', 'Pinel, Gir IS', 'Girardin', 'Girardin IS', 'RP', 'Scellier', 'Duflot', 'LMNP', 'Pinel +'],
    type: ['Studio', 'T1', 'T1 Bis', 'T2', 'T2 Bis', 'T3', 'T3 Bis', 'T4', 'T4 T3', 'T5', 'T6', 'T7', 'T8', 'Maison'],
    story: ['Aucune', 'Duplex', 'Triplex', 'Quadruplex'],
    st: ['', 'Dx', 'Tx', 'Qx'],
    lawyears: ['Aucune', '6', '9', '12'],
    ranges: [
      { text: '100k à 150k', min: 100000, max: 150000 },
      { text: '150k à 200k', min: 150000, max: 200000 },
      { text: '200k à 300k', min: 200000, max: 300000 },
      { text: '+ de 300k', min: 300000, max: 1000000 }
    ],
    defisc: [
      { text: '- 5k', min: 0, max: 5000 },
      { text: '5k à 10k', min: 5000, max: 10000 },
      { text: '10 à 15k', min: 10000, max: 15000 },
      { text: '+ 15k', min: 15000, max: 100000 }
    ],
    exposure: ['Nord-Ouest', 'Nord', 'Nord-Est', 'Est', 'Sud-Est', 'Sud', 'Sud-Ouest', 'Ouest', ''],
    exp: ['N.O', 'N.', 'N.E', 'E.', 'S.E', 'S.', 'S.O', 'O.', '']
  },
  user: {
    role: ['Invité', 'Utilisateur', 'Conseiller', 'Interne', 'Admin', 'SuperAdmin'],
    gender: ['Aucun', 'Mr', 'Mme']
  },
  prospect: {
    list: ['Prospect', 'Client', 'Société', 'Notaire', 'Banque']
  },
  request: {
    status: ['En attente', 'Validé', 'Refusé']
  },
  reservation: {
    status: ['Annulé', 'Optionné', 'Réservé', 'Financé', 'Acté']
  }
}

export default settings
